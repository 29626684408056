import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import {isEmpty, round} from 'lodash'

import MaterialAPI from "../api/Material.api";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import { Material, MaterialInput } from "../types";
import MaterialWeightByMeasuringUnitList from '../components/MaterialWeightByMeasuringUnitList'

export default function MaterialShow() {
  const { id } = useParams();
  const { data: material } = MaterialAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Materials</Typography>
        </Box>

        {material && <MaterialForm material={material} />}
      </Box>
    </Layout>
  );
}

function MaterialForm({ material }: { material: Material }) {
  const [name, setName] = React.useState<string>(material.name);
  const [nitrogenPercentage, setNitrogenPercentage] = React.useState<number>(material.nitrogenPercentage || 0)

  const input: MaterialInput = {
    ...material,
    name,
    nitrogenPercentage: round(nitrogenPercentage*0.01, 2)
  };

  return (
    <BasicShowEdit
      modelApiUseSave={MaterialAPI.useSave}
      modelApiUseDelete={MaterialAPI.useDelete}
      modelInput={input}
    >
      <React.Fragment>
        <TextField
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          label="Nitrogen Percentage"
          value={nitrogenPercentage}
          onChange={(event) => {
            const val = event.target.value;
            if(val.length > 2){
              return;
            }
            if (isEmpty(val)) {
              setNitrogenPercentage(0);
            } else {
              setNitrogenPercentage(parseInt(val));
            }
          }}
          type="number"
            InputLabelProps={{
              shrink: true,
            }}
        />
        <MaterialWeightByMeasuringUnitList material={material} />
      </React.Fragment>
    </BasicShowEdit>
  );
}

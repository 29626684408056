import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { unionBy } from 'lodash'

import {
  BaseQueryProps,
  BaseSearchQueryProps,
  ListResponseData,
  MeasuringUnitWithMaterialWeight,
  MaterialWeightByMeasuringUnit,
  Order,
  Param
} from "../types";
import {
  handleList,
  handleSave
} from "./handler";
import queryClient from "./queryClient";


const methods = {
  useList: ({ materialId, order, orderBy, page, query }: {
    materialId: number;
    page?: number;
    query?: string;
    order?: Order;
    orderBy?: keyof MeasuringUnitWithMaterialWeight;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [{key: "material_id", value: materialId}]
    return useQuery<ListResponseData<MeasuringUnitWithMaterialWeight>>({
      cacheTime: 0,
      queryKey: ["material_weights", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "material_weight_by_measuring_units", order, orderBy, page, query, params }),
    });
  },
  useSave: ({ id, materialId, measuringUnitId, weight }: {
    id?: number;
    materialId?: number;
    measuringUnitId?: number;
    weight?: number;
  }) => {
    return useMutation<MaterialWeightByMeasuringUnit>({
      mutationFn: () =>
        handleSave({ baseUrl: "material_weight_by_measuring_units", input: {
          id,
          materialId,
          measuringUnitId,
          weight
        }}),
      onSuccess: async (data: MaterialWeightByMeasuringUnit) => {
        await queryClient.invalidateQueries(["material_weights"])
      }
    })
  }
}

export default methods;
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isNil, isNaN } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import HarvestAPI from "../../api/Harvest.api";
import ReportAPI from "../../api/Report.api";
import CommodityCreatableAutocomplete from "../../components/CommodityCreatableAutocomplete";
import ShipperCreatableAutocomplete from "../../components/ShipperCreatableAutocomplete";
import {
  Commodity,
  CropRotation,
  Lot,
  Shipper
} from "../../types";
import AddHarvestToLot from './AddHarvestToLot'

const cropWidth = 10;

const yearOfCropRotation = (cropRotation: Array<CropRotation> | CropRotation) => {
  let cropRotationYear: number | undefined = undefined;
  if(cropRotation instanceof Array){
    let maxDate: Date | undefined;
    cropRotation.forEach(cropRotation => {
      if(maxDate === undefined || cropRotation.estHarvestDate > maxDate){
        maxDate = cropRotation.estHarvestDate;
      }
    })
    cropRotationYear = dayjs(maxDate).year()
  } else {
    cropRotationYear = dayjs(cropRotation.estHarvestDate).year()
  }
  return cropRotationYear;
}

const lotRestingDates = (lot: Array<CropRotation> | CropRotation) => {
  let estHarvestDate: Date | undefined = undefined;
  let projectedWetDate: Date | undefined = undefined;
  if(lot instanceof Array){
    lot.forEach((cropRotation: CropRotation) => {
      if(
        estHarvestDate === undefined ||
        (cropRotation.estHarvestDate !== undefined && cropRotation.estHarvestDate > estHarvestDate)
      ){
        estHarvestDate = cropRotation.estHarvestDate;
      }
      if(
        projectedWetDate === undefined ||
        (cropRotation.projectedWetDate !== undefined && cropRotation.projectedWetDate < projectedWetDate)
      ){
        projectedWetDate = cropRotation.projectedWetDate;
      }
    })
  } else {
    estHarvestDate = lot.estHarvestDate;
    projectedWetDate = lot.projectedWetDate;
  }
  return {
    estHarvestDate,
    projectedWetDate
  }
}

const createListOfRestingPeriod = (lots: (Array<CropRotation> | CropRotation)[]) => {
  const restingPeriods: (number | undefined)[] = [];
  for(let leadingLotIndex = 1; leadingLotIndex < lots.length; leadingLotIndex++){
    const leadingLot = lots[leadingLotIndex];
    const leadingLotRestingDates = lotRestingDates(leadingLot);

    const trailingLot = lots[leadingLotIndex - 1];
    const trailingLotRestingDates = lotRestingDates(trailingLot);

    if(leadingLotRestingDates['estHarvestDate'] && trailingLotRestingDates['projectedWetDate']){
      const diffDays = dayjs(leadingLotRestingDates['projectedWetDate']).diff(trailingLotRestingDates['estHarvestDate'], 'd')

      restingPeriods.push(isNaN(diffDays) ? undefined : diffDays)
    } else {
      restingPeriods.push(undefined)
    }
  }
  return restingPeriods;
}

export default function LotCropRotation({
  lot,
  numYearsBack,
  alternate,
  cropRotationMeta,
}: {
  alternate: boolean;
  lot: Lot;
  numYearsBack: number;
  cropRotationMeta: { [years: number]: number };
}) {
  const { data } = ReportAPI.useCropRotation({
    lotId: lot.id,
    numYearsBack,
  });

  const combineSubgroups = (data?: CropRotation[]) => {
    if (!data) return [];
    const sublotGroups = {};
    const lots: (Array<CropRotation> | CropRotation)[] = [];
    data.forEach((harvest) => {
      if (harvest.isSublot) {
        // @ts-expect-error
        if (!sublotGroups[harvest.lotable.sublotGroupId]) {
          // @ts-expect-error
          sublotGroups[harvest.lotable.sublotGroupId] = [];
        }
        // @ts-expect-error
        sublotGroups[harvest.lotable.sublotGroupId].push(harvest);
      } else {
        lots.push(harvest);
      }
    });
    // combine the two
    Object.values(sublotGroups).forEach((sublots) => {
      lots.push(sublots as CropRotation[]);
    });
    return lots;
  };

  const lastestYear = dayjs().year();

  const lots = data ? combineSubgroups(data) : [];

  const restingPeriods = createListOfRestingPeriod(lots)

  const listItems = []
  Object.values(cropRotationMeta).forEach((numPerYear) => {
    const fillMissing = numPerYear === 0 ? 1 : numPerYear;
    Array.from(Array(fillMissing)).forEach((val, i) => {
      listItems.push(
        <Box
          key={i}
          sx={{ mx: '0.1rem', width: `${cropWidth}rem`, backgroundColor: grey[100] }}
        ></Box>
      )
    });
  })

  const currentIndexPerYear: { [year: number]: number } = {}

  let rollingCounterOfMaxCropsPerYear = 0;
  Object.keys(cropRotationMeta).sort((a: string, b: string) => parseInt(b) - parseInt(a)).forEach((numYearsBackIndexStr: string) => {
    const numYearsBackIndex = parseInt(numYearsBackIndexStr);
    const currentYear = lastestYear - numYearsBackIndex;
    currentIndexPerYear[currentYear] = rollingCounterOfMaxCropsPerYear;
    let maxNumCropsInYear = cropRotationMeta[numYearsBackIndex];
    if(maxNumCropsInYear === 0){
      maxNumCropsInYear = 1;
    }
    rollingCounterOfMaxCropsPerYear += (maxNumCropsInYear);
  })

  for(let trailingLotIndex = 0; trailingLotIndex < lots.length; trailingLotIndex++){
    const trailingCropRotation = lots[trailingLotIndex];

    const currentYear = yearOfCropRotation(trailingCropRotation)
    const listItemIndex = currentIndexPerYear[currentYear];
    currentIndexPerYear[currentYear] += 1;

    if (trailingCropRotation instanceof Array) {
      listItems[listItemIndex] = (
        <Box sx={{ mx: '0.1rem', display: "flex", flexDirection: "rows" }}>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "0.25rem",
            }}
          >
            {trailingCropRotation.map((obj, i) => (
              <CropRotationBlock key={obj.id} cropRotation={obj} lot={lot} />
            ))}
          </Box>
          <Box sx={{width: `${cropWidth*0.25}rem`, display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <Typography align="center">{restingPeriods[trailingLotIndex]}</Typography>
          </Box>
        </Box>
      );
    } else {
      listItems[listItemIndex] = (
        <Box sx={{ mx: '0.1rem', display: "flex", flexDirection: "row" }}>
          <CropRotationBlock cropRotation={trailingCropRotation} lot={lot} />
          <Box sx={{width: `${cropWidth*0.25}rem`, display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <Typography align="center">{restingPeriods[trailingLotIndex] ? `${restingPeriods[trailingLotIndex]} days` : ""}</Typography>
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: "0.5rem",
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {listItems.map((li, index) => <React.Fragment key={index}>{li}</React.Fragment>)}
      </Box>

      <AddHarvestToLot lot={lot} />
    </Box>
  );
}

function CropRotationBlock({ cropRotation, lot }: { cropRotation: CropRotation, lot: Lot }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState<boolean>(false);

  const [commodity, setCommodity] = React.useState<Commodity | undefined>(
    cropRotation.commodity,
  );
  const [estHarvestDate, setEstHarvestDate] = React.useState<Dayjs | null>(
    cropRotation.estHarvestDate ? dayjs(cropRotation.estHarvestDate) : null,
  );
  const [projectedWetDate, setProjectedWetDate] = React.useState<Dayjs | null>(
    cropRotation.projectedWetDate ? dayjs(cropRotation.projectedWetDate) : null,
  );
  const [shipper, setShipper] = React.useState<Shipper | undefined>(
    cropRotation.shipper,
  );

  const { mutateAsync } = HarvestAPI.useUpdate({
    id: cropRotation.id,
    estHarvestDate: estHarvestDate ? estHarvestDate.toDate() : undefined,
    projectedWetDate: projectedWetDate ? projectedWetDate.toDate() : undefined,
    commodityId: commodity?.id,
    shipperId: shipper?.id,
  });

  const handleSave = async () => {
    try {
      if (isNil(projectedWetDate) && isNil(estHarvestDate)) {
        enqueueSnackbar(
          "Must select an estimated harvest date or a projected wet date",
          {
            variant: "error",
          },
        );
        return;
      }
      if (isNil(commodity)) {
        enqueueSnackbar("Must select a Commodity", {
          variant: "error",
        });
        return;
      }
      await mutateAsync();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      key={cropRotation.id}
      sx={{
        width: `${cropWidth*0.75}rem`,
        backgroundColor: `${commodity?.cropRotationColor}`,
        cursor: "pointer",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          gap: "0.1rem",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography align="center" variant="body2">
            {commodity?.name}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography align="center" variant="body2">
            HD: {estHarvestDate ? dayjs(estHarvestDate).format("MM/DD/YYYY") : null}
          </Typography>
          <Typography align="center" variant="body2">
            WD: {projectedWetDate ? dayjs(projectedWetDate).format("MM/DD/YYYY") : null}
          </Typography>
        </Box>
      </Box>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatePicker
                label="Projected Wet Date"
                value={projectedWetDate}
                onChange={(value) => setProjectedWetDate(value)}
                sx={{ width: "100%" }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setProjectedWetDate(null),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Est Harvest Date"
                value={estHarvestDate}
                onChange={(value) => setEstHarvestDate(value)}
                sx={{ width: "100%" }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setEstHarvestDate(null),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CommodityCreatableAutocomplete
                setCurrentCommodity={(commodity: Commodity) =>
                  setCommodity(commodity)
                }
                commodity={commodity}
              />
            </Grid>
            <Grid item xs={12}>
              <ShipperCreatableAutocomplete
                setCurrentShipper={(shipper: Shipper) => setShipper(shipper)}
                shipper={shipper}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Button onClick={handleSave} variant="contained" fullWidth>
              Save
            </Button>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="secondary"
              fullWidth
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

import { Box, Button, Modal, TextField, TableCell } from "@mui/material";
import React from "react";
import { useSnackbar } from "notistack";

import {
  MeasuringUnitWithMaterialWeight,
  Order,
  TableColumn,
  Material
} from '../types'
import MaterialWeightByMeasuringUnitApi from '../api/MaterialWeightByMeasuringUnit.api'
import ListTable from "./ListTable";
import Loading from "./Loading";
import { isNil, round } from 'lodash'
import pluralize from 'pluralize'

const columns: TableColumn<MeasuringUnitWithMaterialWeight>[] = [
  { field: "name", label: "Measurement Unit Name" },
  { field: "usage", label: "Usage" },
  { field: "materialWeightByMeasuringUnit", label: "Weight"}
];

function usageFriendlyName(usage: string | undefined) {
  if (usage === "seeds") {
    return "Seeds";
  } else if (usage === "applied_materials") {
    return "Applied Materials";
  }
  return null;
}

function buildTableRow(measuringUnit: MeasuringUnitWithMaterialWeight): JSX.Element[] {
  return [
    <TableCell key={"name"}>{measuringUnit.name}</TableCell>,
    <TableCell key={"usage"}>{usageFriendlyName(measuringUnit.usage)}</TableCell>,
    <TableCell key={"materialWeightByMeasuringUnit"}>
      {isNil(measuringUnit?.materialWeightByMeasuringUnit)
        ? "None defined"
        : measuringUnit?.materialWeightByMeasuringUnit.weight}
    </TableCell>,
  ];
}

// Show all Measuring Units with Column of

export default function MaterialWeightByMeasuringUnitList({
  material
}: {
  material: Material;
}){
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof MeasuringUnitWithMaterialWeight>("name"); // TODO
  const [selectedMaterialWeightByMeasuringUnitId, setSelectedMaterialWeightByMeasuringUnitId] = React.useState<number | undefined>(undefined)
  const [selectedMeasuringUnit, setSelectedMeasuringUnit] = React.useState<MeasuringUnitWithMaterialWeight | undefined>(undefined)
  const [weight, setWeight] = React.useState<string | undefined>(undefined)

  const { data } = MaterialWeightByMeasuringUnitApi.useList({
    query: "",
    order,
    orderBy,
    page,
    materialId: material.id
  })

  const { mutateAsync } = MaterialWeightByMeasuringUnitApi.useSave({
    id: selectedMaterialWeightByMeasuringUnitId,
    measuringUnitId: selectedMeasuringUnit?.id,
    materialId: material.id,
    weight: weight ? round(parseFloat(weight), 2) : 0
  })

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof MeasuringUnitWithMaterialWeight,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (model: MeasuringUnitWithMaterialWeight) => (event: unknown) => {
    if(model.materialWeightByMeasuringUnit){
      setSelectedMaterialWeightByMeasuringUnitId(model.materialWeightByMeasuringUnit?.id)
      setWeight(model.materialWeightByMeasuringUnit?.weight.toString())
    }
    setSelectedMeasuringUnit(model)
  };

  const handleSave = async () => {
    try {
      if(isNil(selectedMeasuringUnit?.id)){
        return enqueueSnackbar("Internal error", {
          variant: "error",
        });
      }
      await mutateAsync();
      handleClose()
      enqueueSnackbar("Saved weight successfully");
    } catch(error){
      enqueueSnackbar("Internal error", {
        variant: "error",
      });
      console.error(error)
    }
  }

  const handleClose = () => {
    setSelectedMaterialWeightByMeasuringUnitId(undefined)
    setSelectedMeasuringUnit(undefined)
    setWeight(undefined)
  }

  return (
    <React.Fragment>
      <Box>
        {data ? (
          <ListTable
            columns={columns}
            buildTableRow={buildTableRow}
            handleChangePage={handleChangePage}
            handleRowClick={handleRowClick}
            handleRequestSort={handleRequestSort}
            meta={data.meta}
            order={order}
            orderBy={orderBy}
            rows={data.data}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <Modal open={!!selectedMeasuringUnit} onClose={handleClose}>
        {selectedMeasuringUnit ? (
          <Box sx={{position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: "1rem"}}
          >
            <Box>
              <TextField
                label={`Weight of one ${pluralize(selectedMeasuringUnit.name, 1)} in lbs. of ${material.name}`}
                // inputProps={{ pattern: /[0-9\.]*/ }}
                value={weight || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  console.log('value', e.target.value)
                  if (isNil(e.target.value) || e.target.value.length === 0) {
                    setWeight(undefined);
                  } else {
                    const newVal = parseFloat(e.target.value);
                    if (newVal >= 0) {
                      if(e.target.value.includes(".")){
                        setWeight(e.target.value)
                      } else {
                        setWeight(newVal.toString());
                      }
                    }
                    if (newVal < 0) setWeight(undefined);
                  }
                }}
                fullWidth
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Button onClick={handleSave} variant="contained" fullWidth>
                Save
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                fullWidth
                color={"secondary"}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        ) : <></>}
      </Modal>
    </React.Fragment>
  )
}